* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

	.ag-header-cell {
		padding-left: 18px;
		padding-right: 18px;
		background-color: #df0000!important;
		color: #fff!important;
	}

/* html,
body {
	height: 100%;
	width: 100%;
	overflow: hidden;
} */

.margin-top-0 {
	margin-top: 0px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.full-cart {
	/* position: absolute !important; */
	z-index: 10000000;
	background: white;
}

.no-full-cart {
	opacity: 0;
}

.column {
	/*   transition: opacity 0.25s ease 0.25s, max-height 0.5s ease, visibility 0s linear 0.5s, top 0s linear 0.5s;
 */
	transition: 0.25s ease 0.1s;
}

.c-table-header {
	border-top: 1px solid #db2828 !important;
	background: #f9fafb !important;
	/* padding-left: 24px !important; */
	border-radius: 0px !important;
}

.left-container {
	overflow-y: scroll;
	height: calc(100vh - 40px - 200px - 110px);
	margin-bottom: 10px;
	padding: 0px 10px;
}

.right-container {
	/* max-width: 80%; */
	max-width: 100%;
	display: flex;
	overflow: auto;
	height: calc(100vh - 60px - 200px - 25px);
	padding: 0px 10px;
	padding-bottom: 10px;
}
.onero-dropdown {
	width: 50px;
}
.cikkszam {
	width: 140px;
	color: gray;
	font-size: 12px;
}

.bold {
	font-weight: bold !important;
}

.no-border-left {
	border-left: 0px !important;
}

.c-table-footer th {
	border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.custom-step {
	margin-bottom: 6px !important;
}

.mini.steps .step {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.mini.steps .step {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.fluid.buttons .compact.button {
	/* height: 52px; */
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.menu {
	/*  height: 64px !important; */
	min-height: 64px !important;
	position: sticky;
	top: 0px;
	z-index: 800;
	margin-bottom: 0px !important;
}

/* .menu .item::before {
  position: relative !important;
  height: 90% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
} */

.menu .right.item:last-child::before {
	width: 0px !important;
}

.logo-menu-item {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	cursor: pointer;
}

.clean-grid {
	padding: 0px !important;
	margin: 0px !important;
}

.positive-color {
	color: #2c662d !important;
}

.positive-block {
	padding-top: 6px !important;
	border-top: 1px solid #2c662d !important;
	background: #fcfff5 !important;

	/* padding-bottom: 20px !important; */

	/* border-radius: 0.28571429rem; */
}

.positive-block .column {
	padding-left: 7px !important;
	padding-right: 7px !important;
}

.positive-block .column:first-child {
	padding-left: 0px !important;
}

.positive-block .column:last-child {
	padding-right: 0px !important;
}

.height-100 {
	height: 100% !important;
}

.no-margin {
	margin: 0px !important;
}

.hiteligeny-dropdown {
	width: 90px !important;
	min-width: 90px !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.cursorPointer {
	cursor: pointer;
}

.homepageAggridHeaderCell {
	background-color: red !important; 
	color: white !important; 
	font-weight: bold !important; 
	text-align: center !important;
}

.homepageAggridHeaderHeader {
	background-color: red !important; 
	border-top: 1px solid black !important; 
	border-bottom: 1px solid black !important;
}

.homepageAggridBodyCell {
	text-align: center !important; 
	border-bottom: 1px solid red !important; 
	border-right: 1px solid red !important;
}

.vodafoneGrid input {
	border-radius: 0px !important;	
}

.vodafoneGridDataCell input {
	max-width: 150px !important;
	text-align: right !important;
	border: 1px solid red !important;
	border-left: none !important;
	border-top: none !important;
}

.vodafoneGridNameCell input {
	min-width: 280px !important;	
	border: 1px solid red !important;
	border-top: none !important;
}

.search input {
	width: 300px !important;
}