body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto!important;

}
*{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.progress {
  position: fixed;
  top: 40%;
  left: calc(50% - 150px);
  z-index: 4000;
  background-color: White;
  border: solid 1px black;
  /* padding: 5px 5px 5px 5px;*/
  text-align: center;
  width:300px;
  font-size: x-large;
  }
   
  .blur {
  width: 100%;
  background-color: black;
  opacity: .7;
  filter: alpha(opacity=70);
  z-index: 3000;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  }
  .ag-header-cell-menu-button{
    opacity:1!important;
    color: white!important;
  }
  .ag-filter-icon{
    color: white!important;

  }
  .ui.search .prompt {
    border-radius: 3px!important;
    /* background-color: #FFF6F6;
  border-color: #E0B4B4;
  color: #9F3A38;
  box-shadow: none; */
}

.ui.searchError .prompt {
  background-color: #FFF6F6;
  border-color: #E0B4B4;
  color: #9F3A38;
  box-shadow: none;
}

.ui.textAreaError .prompt {
  background-color: #FFF6F6;
  border-color: #E0B4B4;
  color: #9F3A38;
  box-shadow: none;
}

.btn {
  border: 1px solid #2185D0;
  background-color: #2185D0;
  border-radius: 5px;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.btn:hover {
  color: white
}

.divError {
  background-color: #FFF6F6;
  border: 1px solid #E0B4B4;
  border-radius: 3px;  
  color: #9F3A38;
  box-shadow: none;
}

.copyToClipboardDiv {
  width: -webkit-fill-available;
}
.rdw-editor-wrapper {
border: 1px solid #F1F1F1;
box-sizing: content-box;
}

.ui.disabled.input {
  opacity: 0.75 !important;
}

.ui.disabled.dropdown {
  opacity: 0.75 !important;
}

.ui.disabled.input {
  opacity: 0.75 !important;
}

.ui.disabled.search {
  opacity: 0.75 !important;
}

.ui.disabled.checkbox label {
  opacity: 0.75 !important;
}

